import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BILLING_CHARGE_URL,
  IMAGE_DNS,
  VFUN_IMAGE_DNS
} from "../../../utils/config/Config";
import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isAuthConfirmState,
  isLauncherState,
  loginState,
  userIdState,
  userInfoState,
  userNicknameState,
  userProfileKeyState
} from "../../../atom/userInfoAtom";
import { menuOpenState, popUpState } from "../../../atom/uiAtom";
import { redirectURL } from "../../../utils/CommonFunc";
import LoginBtn from "../common/LoginBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faAngleDown,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import CombineSideMenu from "./CombineSideMenu/CombineSideMenu";
import SwitchLanguageTab from "../common/SwitchLanguageTab";
import Wallet from "../common/Wallet";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { getGrade } from "../../../api/api";
import { useMediaQuery } from "react-responsive";

function CombineMenu() {
  const { t } = useTranslation();
  const userId = useRecoilValue(userIdState);
  const userNickname = useRecoilValue(userNicknameState);
  const isLogin = useRecoilValue(loginState);
  const [openSideMenu, setOpenSideMenu] = useRecoilState(menuOpenState);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const setIsAuthConfirm = useSetRecoilState(isAuthConfirmState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const service_code = cookies["service_code"];
  const isLauncher = useRecoilValue(isLauncherState);
  const userInfo = useRecoilValue(userInfoState);
  const setHistoryPopUp = useSetRecoilState(popUpState("history"));
  const userProfileKey = useRecoilValue(userProfileKeyState);

  const width1025 = useMediaQuery({
    query: "screen and (min-width: 1025px)"
  });

  const { isLoading, data } = useQuery(
    ["grade"],
    () => getGrade(userInfo.Ssoinfo, userInfo.Userid, userInfo.Userbirth),
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked",
      enabled: isLogin
    }
  );

  const navigate = useNavigate();

  const goBack = (e: React.MouseEvent): void => {
    e.preventDefault();
    navigate(-1);
  };

  const Logout = () => {
    Object.keys(cookies).forEach((keys) => {
      if (cookies["rememberId"] === "Y") {
        if (keys !== "userid" && keys !== "rememberId") {
          if (keys !== "locale") {
            removeCookie(keys, { path: "/", domain: ".valofe.com" });
            // setCookie(keys, "", { path: "/" });
          }
        }
      } else {
        if (keys !== "locale") {
          removeCookie(keys, { path: "/", domain: ".valofe.com" });
          // setCookie(keys, "", { path: "/" });
        }
      }

      window.localStorage.clear();
      if (isLauncher) {
        window.app.vfunRequestLogout();
        redirectURL(
          `${process.env.REACT_APP_PLATFORM_DNS}`,
          "_self",
          isLauncher
        );
      }
      redirectURL(window.location.href, "_self", isLauncher);
    });
  };

  const onClickUserId = () => {
    setIsAuthConfirm(false);
    redirectURL(
      `${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`,
      "_self",
      isLauncher
    );
  };

  return (
    <div id="combineMenu" className="clearfix">
      <div className="wrap-cbm clearfix" id="page-wrap">
        {/* header-left */}
        <ul className="cb-menu pull-left">
          <li className="cbm-logo-li">
            <Link
              to="#"
              className="cbm-logo"
              rel="noreferrer"
              title="vfun-logo"
              onClick={() =>
                redirectURL(
                  process.env.REACT_APP_PLATFORM_DNS ||
                    "https://vfun.valofe.com",
                  "_self",
                  isLauncher
                )
              }
            />
          </li>
          <li className="cbm-back">
            <Link to="#" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
          </li>
          <li className="cbm-bar">
            <Link
              to={window.location.href}
              className="cbm-game"
              title="side-menu"
            >
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => setOpenSideMenu(true)}
              />
            </Link>
          </li>
        </ul>

        {/* SideMenu */}
        {openSideMenu ? <CombineSideMenu /> : null}

        {/* header-right */}
        <fieldset>
          <legend className="hidden">LOGIN FORMULAR</legend>
          {width1025 && <SwitchLanguageTab />}
          {!isLogin ? (
            // 로그인 전 header-right
            <LoginBtn addclassname="login-area" />
          ) : (
            // 로그인 후 header-right
            <ul className="login-area pull-right">
              {/* 유저 정보 */}
              <li className="text user-id">
                <Link to={window.location.href} title={t("my_info")}>
                  {!isLoading ? (
                    data?.data.result === 1 ? (
                      <img
                        src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/${data.data.data}`}
                        alt="g-new"
                        onClick={() =>
                          redirectURL(
                            BILLING_CHARGE_URL(service_code),
                            "_self",
                            isLauncher
                          )
                        }
                      />
                    ) : null
                  ) : (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  )}
                  <span className="text-id" onClick={onClickUserId}>
                    {userNickname === "" ? userId : userNickname}
                  </span>
                </Link>
              </li>

              {/* 최근 로그인 기록 */}
              {/* <li className="text latest-login">
                <Link
                  to={window.location.href}
                  title="Latest Login"
                  onClick={() => setHistoryPopUp(true)}
                >
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/icon-clock.png`}
                  />
                  <span className="latest-time">Latest Login</span>
                </Link>
              </li> */}

              {/* 마이 라운지 btn */}
              <li className="profile-btn">
                <Link
                  to={`${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`}
                >
                  <span className="my-space-btn">My Space</span>
                </Link>
              </li>

              {/* V Wallet */}
              <li className="text user-coin">
                <span
                  className="wallet-click"
                  onClick={() => setIsWalletOpen((prev) => !prev)}
                >
                  <img
                    src={
                      IMAGE_DNS +
                      "/Valofe_file/web/vfun/images/common/icon/icon-wallet.png"
                    }
                    alt="vfun-wallet"
                  />
                  V Wallet
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{ paddingLeft: "10px" }}
                  />
                </span>
                <Wallet isWalletOpen={isWalletOpen} idName="vwallet" />
              </li>

              <li className="login">
                <Link to={window.location.href} onClick={Logout}>
                  {t("signout")}
                </Link>
              </li>
            </ul>
          )}
        </fieldset>
      </div>
    </div>
  );
}

export default withTranslation()(CombineMenu);

import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "../../../atom/userInfoAtom";
import { redirectURL } from "../../../utils/CommonFunc";
import { useCookies } from "react-cookie";

interface IProps {
  addclassname: string;
}

function LoginBtn(props: IProps) {
  const { t } = useTranslation();
  const isLauncher = useRecoilValue(isLauncherState);
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];

  const onClickSignIn = () => {
    if (isLauncher) {
      window.app.vfunRequestLogin();
    } else {
      redirectURL(
        `${process.env.REACT_APP_PLATFORM_DNS}/membership/signin?service_code=${service_code}&ret=${window.location.href}`,
        "_self",
        isLauncher
      );
    }
  };

  const onClickSignUp = () => {
    if (isLauncher) {
      window.app.vfunRequestLogin();
    } else {
      redirectURL(
        `${process.env.REACT_APP_PLATFORM_DNS}/membership/signup?service_code=${service_code}&ret=${window.location.href}`,
        "_self",
        isLauncher
      );
    }
  };

  return (
    <ul className={`${props.addclassname} `}>
      <li className="login" onClick={onClickSignIn}>
        <Link to={window.location.href}>{t("signin")}</Link>
      </li>
      <li className="signup" onClick={onClickSignUp}>
        <Link to={window.location.href}>{t("signup")}</Link>
      </li>
    </ul>
  );
}

export default withTranslation()(LoginBtn);

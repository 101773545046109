import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  langCodeState,
  langListState,
  langState
} from "../../../atom/contentsAtom";
import { useCookies } from "react-cookie";
import { isLauncherState } from "../../../atom/userInfoAtom";

function SwitchLanguageTab() {
  const { t, i18n } = useTranslation();
  const [isLang, setisLang] = useState(false);
  const [lang, setLang] = useRecoilState(langState);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie] = useCookies();
  const langList = useRecoilValue(langListState);
  const isLauncher = useRecoilValue(isLauncherState);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  const hileLang = () => {
    setisLang(!isLang);
  };
  const changeLanguage = (language: string, text: string) => {
    setisLang(!isLang);
    i18n.changeLanguage(language);
    setLang(text);
    setLangCode(language);
    setCookie("locale", language, { path: "/", domain: ".valofe.com" });

    if (!shouldReload) {
      setShouldReload(true);
    }
  };

  useEffect(() => {
    if (isLauncher) {
      window.app.vfunSiteLangChange(langCode);
    }
  }, [isLang, langCode]);

  useEffect(() => {
    setLangCode(cookies["locale"]);
  }, []);

  return (
    <div className="cbm-sel-lang" id="login-area" onClick={hileLang}>
      <Link
        to={window.location.href}
        className={`sel-tit ${isLang ? "on" : ""}`}
      >
        {t(lang)}
      </Link>
      <ul className="view2" style={{ display: `${isLang ? "block" : "none"}` }}>
        {langList.map((i, idx) => (
          <li key={idx}>
            <Link
              to={window.location.href}
              onClick={() => changeLanguage(i.lang, i.lang_code)}
            >
              {t(i.lang_code)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(SwitchLanguageTab);

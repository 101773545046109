//// 서비스 관련 공통
export const Service_type: boolean =
  process.env.REACT_APP_SERVICE_TYPE === "global";

//// 다국어 공통 키
export const loginTranslationKey: string = "This_service_requires_login";

//// 페이지 이동 함수
export function msgRedirectURL(
  msg: string,
  url: string | undefined,
  target: string
) {
  alert(msg);
  window.open(url, target);
}

const data = [
  "vfun",
  "vfun-lounge",
  "billing",
  "vfun-stage",
  "vfun-lounge.stage",
  "billing-qa"
];

export function redirectURL(url: string, target: string, isLauncher: boolean) {
  // vfun, vfun-lounge, vfun-ground, billing,
  // vfun-stage, vfun-lounge.stage, vfun-ground-stage, billing-qa
  // 포함하면 self, 아님 blank
  let isDataIncluded = false;

  for (let i = 0; i < data.length; i++) {
    if (url.includes(data[i])) {
      isDataIncluded = true;
      break;
    }
  }

  if (isLauncher) {
    if (isDataIncluded) {
      window.open(url, "_self");
    } else {
      window.app.vFunOpenWebBrowser(url);
    }
  } else {
    window.open(url, target);
  }
}

// UI
let xPos = 0;
let yPos = 0;
export function checkPosition() {
  xPos = window.screen.width / 2 - 500 / 2; // 가운데 정렬
  yPos = window.screen.height / 2 - 615 / 2;
}

export function naverAuthorizePopUp(url: string) {
  window.open(
    url,
    "",
    `width=500, height=615, left=
      ${xPos}
      , top=
      ${yPos}
      , menubar=yes, status=yes, titlebar=yes, resizable=yes`
  );
}

// 런처 로그인 체크
export const LauncherLogin = (launcherlogin: boolean) => {
  if (launcherlogin) {
    window.app.vfunRequestLogin();
  }
};

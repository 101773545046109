import { atom } from "recoil";
import { Service_type } from "../utils/CommonFunc";

//// 언어
export const langState = atom<string>({
  key: "langState",
  default: "English"
});

export const langCodeState = atom<string>({
  key: "langCodeState",
  default: "en"
});

interface LangList {
  lang: string;
  lang_code: string;
}

export const langListState = atom<LangList[]>({
  key: "langListState",
  default: []
});

export const defaultLangState = atom<{ lang: string; lang_code: string }>({
  key: "defaultLangState",
  default: {
    lang: "",
    lang_code: ""
  }
});

//// hottest game
export const visibleCountState = atom<number>({
  key: "visibleCountState",
  default: 10
});

export const dataLenState = atom<number>({
  key: "dataLenState",
  default: 0
});

export const gameStyleState = atom<string>({
  key: "gameStyleState",
  default: "All"
});

export const gameTypeState = atom<string>({
  key: "gameTypeState",
  default: "all"
});

export const gameNameState = atom<string>({
  key: "gameNameState",
  default: "all"
});

export const pagingNumState = atom<number>({
  key: "pagingNumState",
  default: 1
});

//// service_code
export const serviceCodeState = atom<string>({
  key: "serviceCodeState",
  default: "vfun"
});

//// video
export const videoIdState = atom<string>({
  key: "videoIdState",
  default: ""
});

export const itemDataState = atom({
  key: "itemDataState",
  default: []
});

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation, withTranslation } from "react-i18next";
import { redirectURL, Service_type } from "../../../utils/CommonFunc";
import { BILLING_CHARGE_URL } from "../../../utils/config/Config";
import { isLauncherState, userInfoState } from "../../../atom/userInfoAtom";
import { getGcoin, IGCoin } from "../../../api/api";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";

interface IWalletProps {
  isWalletOpen: boolean;
  idName: string;
}

function Wallet(props: IWalletProps) {
  const { isWalletOpen, idName } = props;
  const { t } = useTranslation();
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];
  const userInfo = useRecoilValue(userInfoState);
  const [gcoin, setGcoin] = useState(0);
  const isLauncher = useRecoilValue(isLauncherState);

  const { isLoading, data } = useQuery<IGCoin>(
    ["gcoin"],
    () =>
      getGcoin(
        service_code || "",
        userInfo.Ssoinfo,
        userInfo.Userid,
        userInfo.Userbirth,
        userInfo.channelingType
      ),
    {
      enabled: !!isWalletOpen
    }
  );

  useEffect(() => {
    if (data?.result !== undefined && data.result === 1) {
      if (Service_type) {
        setGcoin(data.data.bonus_cash + data.data.real_cash);
      } else {
        if (data.data.return_code[0] === "0000") {
          setGcoin(data.data.bonus_cash + data.data.real_cash);
        }
      }
    } else {
      setGcoin(0);
    }
  }, [data]);

  return (
    <div>
      <ul
        id={idName}
        style={{
          display: isWalletOpen ? "block" : "none"
        }}
      >
        <li className="coin clearfix kr">
          <div className="clearfix">
            <span>V</span>
            <table>
              <tbody>
                <tr>
                  <th>{Service_type ? t("gcoins") : t("vcoins")}</th>
                </tr>
                <tr>
                  <td>
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    ) : (
                      gcoin.toLocaleString()
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Link
            to={window.location.href}
            className="btn-charge"
            target="_self"
            title={t("charge")}
            onClick={() =>
              redirectURL(
                BILLING_CHARGE_URL(service_code || "vfun"),
                "_self",
                isLauncher
              )
            }
          >
            {t("charge")}
          </Link>
        </li>
        {Service_type && (
          <li className="point clearfix">
            <div className="clearfix">
              <span>P</span>
              <table>
                <tbody>
                  <tr>
                    <th>Points</th>
                  </tr>
                  <tr>
                    <td>
                      {!isLoading ? (
                        data?.result === 1 ? (
                          data.data.point
                        ) : (
                          0
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Link
              to={window.location.href}
              className="btn-charge"
              target="_self"
              title={"GET"}
            >
              GET
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export default withTranslation()(Wallet);

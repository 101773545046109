import React from "react";
import "../language/i18n";
import CombineMenu from "../component/common/CombineMenu/CombineMenu";
import Footer from "../component/common/Footer/Footer";

function Main() {
  return (
    <>
      <CombineMenu />
      <Footer />
    </>
  );
}

export default Main;

import "./NotFound.scoped.scss";

import Eye from "./Eye";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import CombineMenu from "../../component/common/CombineMenu/CombineMenu";
import { redirectURL } from "../../utils/CommonFunc";
import { isLauncherState } from "../../atom/userInfoAtom";
import { useRecoilValue } from "recoil";

function NotFound() {
  const { t } = useTranslation();
  const isLauncher = useRecoilValue(isLauncherState);

  return (
    <div id="wrapper" className="errors">
      <CombineMenu />
      <div id="content" className="clearfix no-height">
        <section id="inspection" className="clearfix mb0 pb0">
          <article>
            <div id="cont">
              <div className="wrap">
                {/* <!-- ghost --> */}
                <div className="box clearfix">
                  <div className="box__ghost">
                    <div className="symbol" />
                    <div className="symbol" />
                    <div className="symbol" />
                    <div className="symbol" />
                    <div className="symbol" />
                    <div className="symbol" />

                    <div className="box__ghost-container">
                      <Eye />
                      <div className="box__ghost-bottom">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                    <div className="box__ghost-shadow" />
                  </div>
                  <div className="box__ghost-container">
                    <div className="box__ghost-eyes">
                      <div className="box__eye-left" />
                      <div className="box__eye-right" />
                    </div>
                    <div className="box__ghost-bottom">
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                  <div className="box__ghost-shadow" />
                </div>
                {/* <!-- neon --> */}
                <div className="neon">
                  <b>
                    <i>
                      <span id="t0">P</span>
                    </i>
                    <span id="t1">A</span>
                    <span id="t2">G</span>
                    <span id="t3">E</span>

                    <br className="pc-hidden" />

                    <span id="t4">N</span>
                    <span id="t5">O</span>
                    <span id="t6">T</span>

                    <br className="pc-hidden" />

                    <i>
                      <span id="t7">F</span>
                    </i>
                    <span id="t8">O</span>
                    <i>
                      <span id="t9">U</span>
                    </i>
                    <span id="t10">N</span>
                    <span id="t11">D</span>
                  </b>
                </div>
                <p>
                  {t("서비스 이용에 불편을 드려 대단히 죄송합니다.")}
                  <br />
                  {t("찾으시려는 페이지가")}
                  <span className="noti-color"> {t("삭제 또는 변경")} </span>
                  {t("되었거나 잠시 사용하실 수 없는 상태입니다.")}
                </p>
                <p className="ask">
                  {t("동일한 문제가 계속 발생할 경우") + " "}
                  <Link to="/customer/inquiry">{t("고객센터")}</Link>
                  {t("로 문의해주세요.")}
                  <br />
                  {t("빠른 시간 내에 답변 드리겠습니다.")}
                </p>
                {/* <!-- ./ neon --> */}
                <div className="btn-wrap">
                  <Link
                    to="#"
                    onClick={(e) =>
                      redirectURL(
                        process.env.REACT_APP_PLATFORM_DNS ||
                          "https://vfun.valofe.com",
                        "_self",
                        isLauncher
                      )
                    }
                  >
                    {t("홈으로")}
                  </Link>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
}

export default NotFound;

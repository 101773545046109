import { atom, selector } from "recoil";
import { Service_type } from "../utils/CommonFunc";

//// 유저 정보
export const loginState = atom<boolean>({
  key: "loginState",
  default: false
});

export const isLauncherLoginSelector = selector({
  key: "isLauncherLoginSelector",
  get: ({ get }) => {
    const isLauncher = get(isLauncherState);
    const login = get(loginState);

    return isLauncher && !login;
  }
});

export const ssoinfoState = atom<string>({
  key: "ssoinfoState",
  default: "none"
});

export const userIdState = atom<string>({
  key: "userIdState",
  default: "none"
});

export const userBirthState = atom<string>({
  key: "userBirthState",
  default: "none"
});

export const userEmailState = atom<string>({
  key: "userEmailState",
  default: ""
});

export const userReEmailState = atom<string>({
  key: "userReEmailState",
  default: ""
});

export const juminState = atom<string>({
  key: "juminState",
  default: "none"
});

export const userNicknameState = atom<string>({
  key: "userNicknameState",
  default: ""
});

export const userPointState = atom<number>({
  key: "userPointState",
  default: 0
});

export const userProfileKeyState = atom<string>({
  key: "userProfileKeyState",
  default: ""
});

//// 회원가입
interface IInputValue {
  id: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  email: string;
  birth: string;
  nickName: string;
  subject: string;
  description: string;
}

export const inputValueState = atom<IInputValue>({
  key: "inputValueState",
  default: {
    id: "",
    password: "",
    passwordConfirm: "",
    firstName: "",
    lastName: "",
    email: "",
    birth: "",
    nickName: "",
    subject: "",
    description: ""
  }
});

interface IValidText {
  id: string;
  password: string;
  passwordConfirm: string;
  name: string;
  email: string;
  birth: string;
  agree: string;
}

export const validTextState = atom<IValidText>({
  key: "validTextState",
  default: {
    id: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
    birth: "",
    agree: ""
  }
});

interface IIsValid {
  id: boolean;
  password: boolean;
  passwordConfirm: boolean;
  isNext: boolean;
  name: boolean;
  email: boolean;
  birth: boolean;
  agree: boolean;
  isSignIn: boolean;
}

export const isValidState = atom<IIsValid>({
  key: "isValidState",
  default: {
    id: false,
    password: false,
    passwordConfirm: false,
    name: false,
    email: false,
    birth: false,
    isNext: false,
    agree: false,
    isSignIn: false
  }
});

interface IAgree {
  agree_all: boolean;
  agree_14: boolean;
  agree_term: boolean;
  agree_privacy: boolean;
  agree_notice: boolean;
}

export const agreeState = atom<IAgree>({
  key: "agreeState",
  default: {
    agree_all: false,
    agree_14: false,
    agree_term: false,
    agree_privacy: false,
    agree_notice: false
  }
});

// 접속 사이트 체크
export const connectSiteState = atom<string>({
  key: "connectSiteState",
  default: "vfun"
});

export const isLauncherState = atom<boolean>({
  key: "isLauncherState",
  default: false
});

// 유저 정보
interface userInfoProps {
  Ssoinfo: string;
  Userid: string;
  Userbirth: string;
  channelingType: string;
}

export const userInfoState = selector<userInfoProps>({
  key: "userInfoState",
  get: ({ get }) => {
    const ssoinfo = get(ssoinfoState);
    const userId = get(userIdState);
    const userBirth = get(userBirthState);
    const jumin = get(juminState);
    const connectSite = get(connectSiteState);

    const info = {
      Ssoinfo: ssoinfo,
      Userid: userId,
      Userbirth: Service_type ? userBirth : jumin,
      channelingType: connectSite
    };
    return info;
  }
});

//// 네이버 인증
export const isPrivacyAgreeState = atom<boolean>({
  key: "isPrivacyAgreeState",
  default: false
});

export const naverAuthorizeUrl = atom<string>({
  key: "naverAuthorizeUrl",
  default: ""
});

// RememberID
export const rememberIdState = atom<boolean>({
  key: "rememberIdState",
  default: false
});

// 이메일 인증
export const isAuthConfirmState = atom<boolean>({
  key: "isAuthConfirmState",
  default: false
});

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import md5 from "md5";
import ReactGA from "react-ga4";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import "./App.scss";
import {
  checkPosition,
  naverAuthorizePopUp,
  redirectURL,
  Service_type
} from "./utils/CommonFunc";
import {
  connectSiteState,
  isLauncherState,
  isPrivacyAgreeState,
  isValidState,
  juminState,
  loginState,
  naverAuthorizeUrl,
  ssoinfoState,
  userBirthState,
  userEmailState,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "./atom/userInfoAtom";
import { initGA } from "./utils/tracking";
import {
  GET_MEMBERINFO,
  ILang,
  ILoginData,
  IMember,
  checkWebTokenApi,
  getLanguage,
  naverMemberStatus
} from "./api/api";
import { VERIFY_KEY } from "./utils/config/Config";
import WrapLoading from "./component/loading/WrapLoading";
import PageRoutes from "./routers/PageRoutes";
import {
  defaultLangState,
  langCodeState,
  langListState,
  langState
} from "./atom/contentsAtom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import { t } from "i18next";

function App(props: any) {
  const setIsLogin = useSetRecoilState(loginState);
  const setUserID = useSetRecoilState(userIdState);
  const setSSOInfo = useSetRecoilState(ssoinfoState);
  const setJuminInfo = useSetRecoilState(juminState);
  const setUserBirth = useSetRecoilState(userBirthState);
  const setUserEmail = useSetRecoilState(userEmailState);
  const setConnectSite = useSetRecoilState(connectSiteState);
  const setIsPrivacyAgree = useSetRecoilState(isPrivacyAgreeState);
  const setNaverAuthorize = useSetRecoilState(naverAuthorizeUrl);
  const setLangList = useSetRecoilState(langListState);
  const setLang = useSetRecoilState(langState);
  const setUserNickName = useSetRecoilState(userNicknameState);
  const setIsLauncher = useSetRecoilState(isLauncherState);
  const setUserProfileKey = useSetRecoilState(userProfileKeyState);
  const [defaultLang, setDefaultLang] = useRecoilState(defaultLangState);

  const isValid = useRecoilValue(isValidState);
  const launcher = window.navigator.userAgent.split("::")[1];
  const isLauncher = useRecoilValue(isLauncherState);
  const [isLoading, setIsLoading] = useState(true);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const langList = useRecoilValue(langListState);
  const { i18n } = useTranslation();
  const service_code = cookies["service_code"];

  // 언어
  const { isLoading: langLoading, data: langData } = useQuery<ILang>(
    ["lang"],
    () => getLanguage("vfun"),
    {
      refetchOnWindowFocus: false
    }
  );

  // 런처 여부
  useEffect(() => {
    if (launcher === "vfun-launcher") {
      setIsLauncher(true);
    }
  }, [launcher]);

  // 런처 로그인, 아웃
  useEffect(() => {
    if (isLauncher) {
      window.app.vfunSitInitFinish();
    }
    window.vfunLogin = async function (webToken: string) {
      try {
        let res = await axios.post(checkWebTokenApi, {
          web_token: webToken
        });
        const data: ILoginData = res.data;
        if (res.data.result === 1) {
          document.cookie = `sso_info_new=${data.data.sso_info_new}; path=/; domain=.valofe.com`;
          document.cookie = `sso_info=${data.data.sso_info}; path=/; domain=.valofe.com`;
          document.cookie = `gender=${data.data.gender}; path=/; domain=.valofe.com`;
          document.cookie = `email=${data.data.email}; path=/; domain=.valofe.com`;
          setCookie("birthday", data.data.birthday, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("userid", data.data.user_id, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("SSOKey", data.data.SSOKey, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("country", data.data.country, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("auth", data.data.auth, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("member_type", data.data.member_type, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("check_email", data.data.check_email, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("user_profile_key", data.data.user_profile_key, {
            path: "/",
            domain: ".valofe.com"
          });
          if (service_code === "vfun") {
            redirectURL(window.location.href, "_self", isLauncher);
          } else {
            redirectURL(
              `${process.env.REACT_APP_PLATFORM_DNS}/library?service_code=${service_code}`,
              "_self",
              isLauncher
            );
          }
        } else {
          alert(t("my_inquiry_desc13"));
        }
      } catch (error) {
        console.log("launcher", error);
      }
    };
    window.vfunLogout = function () {
      Object.keys(cookies).forEach((keys) => {
        if (cookies["rememberId"] === "Y") {
          if (keys !== "userid" && keys !== "rememberId") {
            if (keys !== "locale") {
              removeCookie(keys, { path: "/", domain: ".valofe.com" });
            }
          }
        } else {
          if (keys !== "locale") {
            removeCookie(keys, { path: "/", domain: ".valofe.com" });
          }
        }

        window.localStorage.removeItem("prevPath");
        redirectURL(
          `${process.env.REACT_APP_PLATFORM_DNS}`,
          "_self",
          isLauncher
        );
      });
    };

    window.vfunLangChange = function (
      lang: string,
      lang_code: string,
      vfunLauncherLang: string
    ) {
      setCookie("locale", lang, { path: "/", domain: ".valofe.com" });
      setCookie("vfunLauncherLocale", vfunLauncherLang, {
        path: "/",
        domain: ".valofe.com"
      });
      i18n.changeLanguage(lang);
      setLang(lang_code);
      setLangCode(lang);
    };
  }, []);

  useEffect(() => {
    if (langData?.result === 1) {
      setLangList(langData.data);
      setDefaultLang({
        lang: langData.default_language.lang,
        lang_code: langData.default_language.lang_code
      });
    }
  }, [langLoading]);

  // 언어 쿠키 참조
  useEffect(() => {
    if (Service_type) {
      // 글로벌 일 경우
      if (cookies["locale"] !== undefined || cookies["locale"] !== null) {
        for (let i = 0; i < langList.length; i++) {
          if (langList[i].lang === cookies["locale"]) {
            setCookie("locale", cookies["locale"], {
              path: "/",
              domain: ".valofe.com"
            });
            setLang(langList[i].lang_code);
            setLangCode(cookies["locale"]);
            i18n.changeLanguage(cookies["locale"]);
          }
        }
      } else {
        setCookie("locale", defaultLang.lang, {
          path: "/",
          domain: ".valofe.com"
        });
        setLang(defaultLang.lang_code);
        setLangCode(defaultLang.lang);
        i18n.changeLanguage(defaultLang.lang);
      }
    } else {
      setCookie("locale", "ko", { path: "/", domain: ".valofe.com" });
      setLang("한국어");
      setLangCode("ko");
      i18n.changeLanguage("ko");
    }
  }, [langList]);

  // google GA
  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      valofe_process();
      setConnectSite("vfun");
      //2023. 7월 1일부터 google GA가 UA 속성 지원하지 않음
      initGA(Service_type ? "G-L2XKNC23GQ" : "G-5V54JQQ53S");
      ReactGA.send("pageview"); // 페이지 뷰가 다른 이벤트 추척보다 선행되어야 함
      ReactGA.event({
        category: "User",
        action: "Created an Account", //계정생성 추적
        label: Service_type ? "VFUN Lounge 회원 가입" : "VFUN KR 회원 가입" // + 서비스 코드가 들어가면 더 좋겠습니다.
      });
    } else {
      naver_process();
      window.addEventListener(
        "message",
        (e) => e.data.message === "naver_agree" && window.location.reload()
      );
      setConnectSite("naver");
    }
  }, []);

  // uuid
  useEffect(() => {
    if (window.location.href.includes("uuid")) {
      const { cookies } = props;
      const uuid = window.location.search.replace("?uuid=", "");

      cookies.set("_vl", btoa(uuid));
      // console.log(cookies);
    }
  }, []);

  const valofe_process = async () => {
    const { cookies } = props;

    // console.log("cookies", cookies);
    if (Service_type) {
      // 글로벌
      setIsPrivacyAgree(true);
      let cookies_ssoinfo = cookies.get("sso_info_new");
      let cookies_userid = cookies.get("userid");
      let cookies_birthday = cookies.get("birthday");

      if (cookies_ssoinfo !== undefined) {
        try {
          let res: any = await GET_MEMBERINFO({
            Ssoinfo: encodeURIComponent(cookies_ssoinfo),
            Userid: cookies_userid,
            Userbirth: cookies_birthday
          });
          if (res.result === 1) {
            const userData: IMember = res;
            setUserID(userData.data.user_id);
            setSSOInfo(userData.sso_info);
            setUserBirth(userData.data.user_birthday);
            setUserEmail(userData.data.email);
            setUserNickName(userData.data.nickName || "");
            setUserProfileKey(cookies.get("user_profile_key"));
            setIsLogin(true);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    } else {
      // 한국
      setIsPrivacyAgree(true);
      let cookies_goonzu = cookies.get("goonzu");
      if (cookies_goonzu === undefined) {
        setIsLogin(false);
      } else {
        let cookies_info = cookies_goonzu.split("&");
        if (cookies_info.length > 0) {
          let param: any = [];
          for (let i = 0; i < cookies_info.length; i++) {
            let key = cookies_info[i].split("=")[0];
            let value = cookies_info[i].split("=")[1];
            param[key] = value;
          }
          let jumin = cookies.get("jumin");
          let ssoinfo = cookies.get(process.env.REACT_APP_SSO_INFO_COOKIE_NAME);
          let user_id = decodeURI(param["userid"]);
          let cookies_string = user_id + jumin + VERIFY_KEY;
          if (md5(cookies_string) === ssoinfo) {
            setUserID(user_id);
            setSSOInfo(ssoinfo);
            setJuminInfo(jumin);
            setIsLogin(true);
          } else {
            setIsLogin(false);
          }
        } else {
          setIsLogin(false);
        }
      }
      setIsLoading(false);
    }
  };

  const naver_process = async () => {
    const { cookies } = props;
    const serviceCode = window.location.pathname.split("/")[2];
    const gdp_login = cookies.get("GDP_LOGIN");

    if (gdp_login === undefined) {
      setIsLogin(false);
      setIsPrivacyAgree(false);
    } else {
      try {
        let res: any = await naverMemberStatus(
          {
            loginkey: gdp_login
          },
          serviceCode
        );

        if (res.error_code !== "1000") {
          checkPosition();
          naverAuthorizePopUp(res.authorize);

          setIsPrivacyAgree(false);
          setNaverAuthorize(res.authorize);
        } else {
          let ssoinfo = res.access_token;
          let user_id = res.userid;
          setSSOInfo(ssoinfo);
          setUserID(user_id);
          setIsPrivacyAgree(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
      setIsLogin(true);
    }
    setIsLoading(false);
  };

  // 로그인 재호출
  useEffect(() => {
    if (isValid.isSignIn) {
      valofe_process();
    }
  }, [isValid.isSignIn]);

  return <Router>{isLoading ? <WrapLoading /> : <PageRoutes />}</Router>;
}

export default withCookies(App);

import React, { useState } from "react";
import { useWindowSize } from "../../utils/WindowSize";

export default function Eye() {
  const { width: pageX, height: pageY } = useWindowSize();
  const [state, setState] = useState({ xAxis: -50, yAxis: 0 });

  React.useEffect(() => {
    const mymousemove = (event: MouseEvent) => {
      let mouseY = event.pageY;
      let yAxis = ((pageY / 2 - mouseY) / pageY) * 300;
      //horizontalAxis
      let mouseX = event.pageX / -pageX;
      let xAxis = -mouseX * 100 - 100;
      setState({ ...state, ...{ xAxis, yAxis } });
    };
    document.addEventListener("mousemove", mymousemove);
    return () => {
      document.removeEventListener("mousemove", mymousemove);
    };
  }, [state, pageX, pageY]);

  return (
    <div
      className="box__ghost-eyes"
      style={{
        transform: "translate(" + state.xAxis + "%,-" + state.yAxis + "%)"
      }}
    >
      <div className="box__eye-left"></div>
      <div className="box__eye-right"></div>
    </div>
  );
}

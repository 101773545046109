import { Service_type } from "../CommonFunc";

//// 사이트 정보 키
export const SERVICE_CODE = "vfun";
export const VERIFY_KEY: string = "ehG.NSdz.qoffhvm!@";

//// UI
export const IMAGE_DNS: string = "https://file.qijisoft.com";
export const VFUN_IMAGE_DNS: string = "https://file.qijisoft.com/Valofe_file";

//// 이동 URL
export const BILLING_CHARGE_URL = (service_code: string) => {
  return (
    process.env.REACT_APP_VFUN_API_DNS +
    `${
      Service_type
        ? `/api/common/redirect/billing?service_code=${service_code}&type=purchase`
        : "/mycash/charge_info.asp"
    }`
  );
};

export const PRIVACY_POLICY_URL = (service_code: string) =>
  `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_privacy?service_code=${service_code}&language=en`;

export const TERMS_OF_AGREEMENT_URL = (service_code: string) =>
  `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_terms?service_code=${service_code}&language=en`;

export const YOUTUBE_URL: string =
  "https://www.youtube.com/channel/UCBOhYvVCI0InwSoyCWsbPZw/videos";

export const VFUN_SITE: string = "https://www.valofe.com";

import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isAuthConfirmState,
  isLauncherState,
  loginState,
  userIdState,
  userInfoState,
  userNicknameState,
  userProfileKeyState
} from "../../../../atom/userInfoAtom";
import { menuOpenState, popUpState } from "../../../../atom/uiAtom";
import { useTranslation, withTranslation } from "react-i18next";
import LoginBtn from "../../common/LoginBtn";
import SideMenuList from "./SideMenuList";
import SwitchLanguageTab from "../../common/SwitchLanguageTab";
import { useCookies } from "react-cookie";
import BannerSlider from "../../BannerSlider/BannerSlider";
import { Service_type, redirectURL } from "../../../../utils/CommonFunc";
import {
  BILLING_CHARGE_URL,
  VFUN_IMAGE_DNS
} from "../../../../utils/config/Config";
import { useQuery } from "react-query";
import { getGrade } from "../../../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function CombineSideMenu() {
  const { t } = useTranslation();
  const isLogin = useRecoilValue(loginState);
  const userId = useRecoilValue(userIdState);
  const userNickname = useRecoilValue(userNicknameState);
  const setIsAuthConfirm = useSetRecoilState(isAuthConfirmState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const service_code = cookies["service_code"];
  const [openSideMenu, setOpenSideMenu] = useRecoilState(menuOpenState);
  const isLauncher = useRecoilValue(isLauncherState);
  const userInfo = useRecoilValue(userInfoState);
  const setHistoryPopUp = useSetRecoilState(popUpState("history"));
  const userProfileKey = useRecoilValue(userProfileKeyState);

  const { isLoading, data } = useQuery(
    ["grade"],
    () => getGrade(userInfo.Ssoinfo, userInfo.Userid, userInfo.Userbirth),
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked",
      enabled: isLogin
    }
  );

  const Logout = () => {
    Object.keys(cookies).forEach((keys) => {
      if (cookies["rememberId"] === "Y") {
        if (keys !== "userid" && keys !== "rememberId") {
          if (keys !== "locale") {
            removeCookie(keys, { path: "/", domain: ".valofe.com" });
            // setCookie(keys, "", { path: "/" });
          }
        }
      } else {
        if (keys !== "locale") {
          removeCookie(keys, { path: "/", domain: ".valofe.com" });
          // setCookie(keys, "", { path: "/" });
        }
      }

      window.localStorage.removeItem("prevPath");
      if (isLauncher) {
        window.app.vfunRequestLogout();
        redirectURL(
          `${process.env.REACT_APP_PLATFORM_DNS}`,
          "_self",
          isLauncher
        );
      }
      redirectURL(window.location.href, "_self", isLauncher);
    });
  };

  const onClickUserId = () => {
    setIsAuthConfirm(false);
    redirectURL(
      `${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`,
      "_self",
      isLauncher
    );
  };

  return (
    <Menu
      pageWrapId={"page-wrap"}
      outerContainerId={"combineMenu"}
      customBurgerIcon={false}
      isOpen={openSideMenu}
      onClose={() => {
        setOpenSideMenu(false);
      }}
    >
      <div id="cbm-aside">
        <div className="side-menu">
          {/*<!-- sideMenu 상단 로고와 x -->*/}
          <h1 className="tit-service">
            <Link
              to={window.location.href}
              className="cbm-logo"
              onClick={() =>
                redirectURL(
                  process.env.REACT_APP_PLATFORM_DNS ||
                    "https://vfun.valofe.com",
                  "_self",
                  isLauncher
                )
              }
            />
            <Link
              to={window.location.href}
              className="btn-cbm-close"
              onClick={() => setOpenSideMenu(() => false)}
            />
          </h1>

          {/* 회원 정보 + 다국어 */}
          <div className="side-menu-top">
            {!isLogin ? (
              // 로그인 전
              <div className="user-info not-login clearfix">
                <LoginBtn addclassname="clearfix" />
                <SwitchLanguageTab />
              </div>
            ) : (
              // 로그인 후
              <div className="user-info login clearfix">
                <span className="user-name">
                  {Service_type ? (
                    <Link
                      to={`${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`}
                      onClick={() => setOpenSideMenu(false)}
                    >
                      <span className="my-space-btn">My Space</span>
                    </Link>
                  ) : (
                    t("welcome")
                  )}

                  {/* 최근 로그인 기록 */}
                  {/* <Link
                    to={window.location.href}
                    title="Latest Login"
                    onClick={() => setHistoryPopUp(true)}
                  >
                    <img
                      src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/icon-clock.png`}
                    />
                  </Link> */}
                  <Link
                    to={window.location.href}
                    className="info"
                    title={t("my_info")}
                  >
                    {!isLoading ? (
                      data?.data.result === 1 ? (
                        <img
                          src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/${data.data.data}`}
                          alt="g-new"
                          onClick={() =>
                            redirectURL(
                              BILLING_CHARGE_URL(service_code),
                              "_self",
                              isLauncher
                            )
                          }
                        />
                      ) : null
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    )}
                    <span
                      className="color-theme pull-right"
                      onClick={onClickUserId}
                    >
                      {userNickname === "" ? userId : userNickname}
                    </span>
                  </Link>
                </span>
                <ul className="pull-right clearfix">
                  <li>
                    <Link to={window.location.href} onClick={onClickUserId}>
                      {t("my_info")}
                    </Link>
                  </li>
                  <li>
                    <Link to={window.location.href} onClick={Logout}>
                      {t("signout")}
                    </Link>
                  </li>
                  <SwitchLanguageTab />
                </ul>
              </div>
            )}
          </div>

          {/* sideMenus 카테고리 */}
          <SideMenuList />
          {/* <BannerSlider /> */}
        </div>
      </div>
    </Menu>
  );
}

export default React.memo(withTranslation()(CombineSideMenu));

import { atom, atomFamily } from "recoil";

//// UI 요소
// combineMenu
export const menuOpenState = atom<boolean>({
  key: "menuOpenState",
  default: false
});

export const popUpState = atomFamily({
  key: "pointPopUpState",
  default: false
});

export const videoPopUpState = atom<boolean>({
  key: "videoPopUpState",
  default: false
});

export const popUpNewsState = atom<boolean>({
  key: "popUpNewsState",
  default: false
});

export const newsDataIdxState = atom<number>({
  key: "newsDataIdxState",
  default: 0
});

export const wrapLoadingState = atom<boolean>({
  key: "wrapLoadingState",
  default: false
});

// Vfun Sign Up Step
interface IsStepProps {
  one: boolean;
  two: boolean;
  three: boolean;
}

export const isStepState = atom<IsStepProps>({
  key: "isStepState",
  default: {
    one: true,
    two: false,
    three: false
  }
});

// CookieNotice
export const cookieNoticeHiddenState = atom<boolean>({
  key: "isCookieNoticeState",
  default: false
});

// library
export const libraryInfoToggleState = atom<boolean>({
  key: "libraryInfoToggleState",
  default: false
});

// 좋아요
export const isLikeState = atom<boolean>({
  key: "isLikeState",
  default: false
});

export const likeCountState = atom<number>({
  key: "likeCountState",
  default: 0
});

export const isLikeLoadingState = atom<boolean>({
  key: "isLikeLoadingState",
  default: false
});

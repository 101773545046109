import "./NotSupportPage.scoped.scss";
import { useTranslation } from "react-i18next";
import { IMAGE_DNS } from "../../utils/config/Config";
import CombineMenu from "../../component/common/CombineMenu/CombineMenu";
import Shortcuts from "./ShortcutsBtn";
import InstallBox from "./InstallBox";

function NotSupportPage() {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <CombineMenu />
      <div id="NotSupport" className="clearfix">
        <div className="guide-top">
          <div className="guide-funny">
            <img
              src={
                IMAGE_DNS +
                "/Valofe_file/web/vfun/images/vfun_lounge/images/funny-browser-gude.png"
              }
              alt="funny-browser-gude"
            />
          </div>
          <div className="text">
            {t("VFUN 라운지는 Chrome 또는 Edge에 최적화 되어 있습니다.")}
          </div>
          <div className="btns">
            <Shortcuts point="이상" num="1" />
            <Shortcuts point="이하" num="2" />
          </div>
        </div>
        <div className="guide-bottom">
          <InstallBox point="미만" num="2" />
          <InstallBox point="이상" num="1" type="edge" />
        </div>
      </div>
    </div>
  );
}

export default NotSupportPage;

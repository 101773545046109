import { t } from "i18next";
import Reaact, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { redirectURL } from "../../../utils/CommonFunc";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_AGREEMENT_URL,
  VFUN_IMAGE_DNS,
  VFUN_SITE,
  YOUTUBE_URL
} from "../../../utils/config/Config";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "../../../atom/userInfoAtom";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { IFooter, getFooter } from "../../../api/api";

function Footer() {
  const isLauncher = useRecoilValue(isLauncherState);
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];
  const locale = cookies["locale"];
  const year = new Date().getFullYear();
  const footerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null);

  const { isLoading, data } = useQuery<IFooter>(
    ["footer"],
    () => getFooter(service_code),
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked"
    }
  );

  useEffect(() => {
    const handleResize = () => {
      if (footerRef.current) {
        const height = footerRef.current.clientHeight;
        setHeight(height);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const wrapper = document.getElementById("wrapper");
    if (wrapper !== null) {
      wrapper.style.paddingBottom = `${String(height)}px`;
    }
  }, [height]);

  return (
    <footer id="valofe-footer" className="clearfix vfun" ref={footerRef}>
      <div className="row-w clearfix sns-list">
        <div className="sns pull-right">
          <ul>
            {/* 연령 제한 */}
            {service_code === "lostsaga-tw" && (
              <li>
                <div className="grade pull-right">
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/vfun/images/cbm/grade/grade_6_tw.png`}
                  />
                  <p>
                    本遊戲為免費使用，部分內容涉及暴力情節。
                    <br />
                    遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
                    <br />
                    請注意遊戲時間，避免沉迷。
                    <br />
                    本遊戲服務區域為台灣。
                  </p>
                </div>
              </li>
            )}
            {service_code === "icarus-sea" && locale === "tw" ? (
              <li>
                <div className="grade pull-right">
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/vfun/images/cbm/grade/grade_12_tw.png`}
                  />
                  <p>
                    本遊戲為免費使用，部分內容涉及暴力情節。
                    <br />
                    遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
                    <br />
                    請注意遊戲時間，避免沉迷。
                    <br />
                    本遊戲服務區域為台灣。
                  </p>
                </div>
              </li>
            ) : null}
            {service_code === "blacksquad-tw" && locale === "tw" ? (
              <li>
                <div className="grade pull-right">
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/vfun/images/cbm/grade/grade_15_tw.png`}
                  />
                  <p>
                    本遊戲為免費使用，部分內容涉及暴力情節。
                    <br />
                    遊戲內另提供購買虛擬遊戲幣、物品等付費服務。
                    <br />
                    請注意遊戲時間，避免沉迷。 本遊戲服務區域為台灣。
                    <br />
                  </p>
                </div>
              </li>
            ) : null}
            {service_code == "cronous-online" && (
              <li>
                <div className="grade pull-right">
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/vfun/images/cbm/grade/grade_15_tw.png`}
                  />
                  <p>
                    本遊戲部份內容涉及之情節。
                    <br />
                    本遊戲為免費使用，遊戲內另提供購買虛擬幣、物品等付費。
                    <br />
                    請注意遊戲時間避免沉迷。
                    <br />
                  </p>
                </div>
              </li>
            )}
            {service_code === "c9-gl" && locale === "ko" ? (
              <li>
                <div className="grade pull-right">
                  <img
                    src={`${VFUN_IMAGE_DNS}/web/grade/grade_15.png`}
                    alt="15세 이용가"
                  />
                  <table className="tbl-grade">
                    <tbody>
                      <tr>
                        <td>제명</td>
                        <td>C9</td>
                        <td>상호</td>
                        <td>(주)밸로프</td>
                      </tr>
                      <tr>
                        <td>이용등급</td>
                        <td>15세 이용가</td>
                        <td>등급분류번호</td>
                        <td>GC-CC-NP-240614-009</td>
                      </tr>
                      <tr>
                        <td>등급분류 일자</td>
                        <td>2024-06-14</td>
                        <td>제작배급업자등록번호</td>
                        <td>제2015-000002호</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            ) : null}

            {!isLoading
              ? data?.result === 1
                ? data.data.data.map((i) => (
                    <li key={i.sns_type}>
                      <Link
                        to={i.sns_url}
                        className={`icon ${i.sns_type}`}
                        title="VFUN Lounge"
                      />
                    </li>
                  ))
                : null
              : null}
          </ul>
        </div>
      </div>
      <div className="row-w clearfix">
        <div className="line clearfix"></div>
      </div>
      <div className="row-w clearfix">
        <div className="copyright pull-left">
          <div className="footer-logo" id="vfun">
            <span
              className="logo"
              onClick={() => redirectURL(VFUN_SITE, "_self", isLauncher)}
            />
          </div>
          <p>
            <span className="entity-c">©</span>
            <span className="year-of-copyrights">{year}</span> VALOFE GLOBAL
            Ltd. All rights reserved.
          </p>
        </div>
        <nav className="nav pull-right">
          <ul>
            <li>
              <Link
                to={window.location.href}
                target="_self"
                onClick={() =>
                  redirectURL(`${VFUN_SITE}/company/intro`, "_self", isLauncher)
                }
              >
                {t("about_valofe")}
              </Link>
            </li>
            <li>
              <Link to={PRIVACY_POLICY_URL(service_code || "vfun")}>
                {t("privacy_policy")}
              </Link>
            </li>
            <li>
              <Link to={TERMS_OF_AGREEMENT_URL(service_code || "vfun")}>
                {t("terms_of_use")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;

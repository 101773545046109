import axios from "axios";
import { VFUN } from "../utils/config/request";

export const BASE_PATH = process.env.REACT_APP_VFUN_API_DNS;
export const POST_GET_MEMBERINFO = `${BASE_PATH}/api/vfun/member_info`;
export const GET_NAVER_MEMBERINFO = `${BASE_PATH}/api/naver/get_member_status/`;

export const HeadersInfo = (
  ssoInfo: string,
  userID: string,
  userBirth: string,
  connectSite: string,
  type: string
) => {
  return {
    headers: {
      "Content-Type": type,
      Ssoinfo: ssoInfo,
      Userid: userID,
      Userbirth: userBirth,
      channelingType: connectSite
    }
  };
};

export interface IMember {
  data: {
    email: string;
    nickName: string | null;
    user_Serial: string;
    user_birthday: string;
    user_id: string;
  };
  result: number;
  sso_info: string;
}

export const GET_MEMBERINFO = (headers: {}) => {
  return VFUN({
    url: POST_GET_MEMBERINFO,
    method: "get",
    headers
  });
};

export const naverMemberStatus = (headers: {}, serviceCode: string) => {
  return VFUN({
    url: GET_NAVER_MEMBERINFO + `${serviceCode}`,
    method: "get",
    headers
  });
};

//// 언어 API (Languages)
interface ILangData {
  lang: string;
  lang_code: string;
}

export interface ILang {
  result: number;
  data: ILangData[];
  default_language: {
    lang: string;
    lang_code: string;
  };
}

export const getLanguage = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/support_languages/${service_code}`)
    .then((res) => res.data);

//// 배너 API (Banner)
interface IBannerData {
  idx: number;
  banner_name: string;
  banner_image_url: string;
  banner_hover_image_url: string;
  language: string;
  target: string;
  target_url: string;
  start_date: string;
  end_date: string;
}

export interface IBanner {
  result: number;
  data: IBannerData[];
}

export const getBanner = () =>
  axios
    .get(`${BASE_PATH}/api/vfun/combinemenu_banners`)
    .then((res) => res.data);

//// 사이드 메뉴 리스트 (SideMenu)
export interface ISub_Menu {
  created_at: string;
  menu_code: string;
  target: string;
  target_url: string;
  title: string;
}

export interface IMenuData {
  menu_code: string;
  sub_menu: string;
  sub_menus: ISub_Menu[];
}

export interface ICombineMenu {
  result: number;
  data: IMenuData[];
}

export const getCombineMenu = (
  ssoInfo: string,
  userID: string,
  userBirth: string,
  connectSite: string,
  langCode: string
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/combinemenus?lang=${langCode}`,
      HeadersInfo(ssoInfo, userID, userBirth, connectSite, "application/json")
    )
    .then((res) => res.data);

// 지갑, 코인 (gcoin)
interface IGCoinData {
  bonus_cash: number;
  real_cash: number;
  return_code: any;
  point: number;
}

export interface IGCoin {
  result: number;
  data: IGCoinData;
}

export const getGcoin = (
  service_code: string,
  ssoInfo: string,
  userID: string,
  userBirth: string,
  connectSite: string
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/gcoin/balance/${service_code}`,
      HeadersInfo(ssoInfo, userID, userBirth, connectSite, "application/json")
    )
    .then((res) => res.data);

export const getGrade = (ssoInfo: string, userID: string, userBirth: string) =>
  axios.get(
    `${BASE_PATH}/api/vfun/billingGrade`,
    HeadersInfo(ssoInfo, userID, userBirth, "", "application/json")
  );

//// My Point
export const getMyPoint = (
  ssoInfo: string,
  userID: string,
  userBirth: string
) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/mypoint`,
      HeadersInfo(ssoInfo, userID, userBirth, "", "application/json")
    )
    .then((res) => res.data);

//footer

interface IFooterData {
  sns_type: string;
  sns_url: string;
}

export interface IFooter {
  data: {
    code: number;
    data: IFooterData[];
  };
  result: number;
}

export const getFooter = (service_code: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/footer/sns/${service_code}`)
    .then((res) => res.data);

//// Sign IN
export interface ILoginData {
  data: {
    birthday: string;
    check_email: string;
    email: string;
    member_type: string;
    service_code?: string;
    sso_info: string;
    sso_info_new: string;
    user_id: string;
    SSOKey: number;
    gender: string;
    country: string;
    auth: number;
    user_profile_key: string;
  };
  result: number;
}

// 런처 로그인
export const checkWebTokenApi = `${BASE_PATH}/api/auth/checkWebToken`;

import React, { useEffect, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { MAIN_HOME_PAGE_CODE } from "./contant";
import { browserName } from "react-device-detect";
import Main from "../view/Main";

import NotSupportPage from "../error/notSupport/NotSupportPage";

import WrapLoading from "../component/loading/WrapLoading";
import NotFound from "../error/notFound/NotFound";

const PageRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.pathname === "/home" && navigate("/");
  }, []);

  // 코드 변경으로 인해 다시 확인해보기
  if (browserName === "IE") {
    return <NotSupportPage />;
  }

  return (
    <Suspense fallback={<WrapLoading />}>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path={MAIN_HOME_PAGE_CODE} element={<Main />} />
        </Route>
        <Route path="*" element={<Main />} />
        <Route path="/notfound" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default PageRoutes;

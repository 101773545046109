import "./WrapLoading.scss";

function WrapLoading() {
  return (
    <div className="wrap-loading">
      <div className="loading-container">
        <div className="spinner__container spinner__effect--8">
          <ul className="spinner">
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
            <li className="spinner__square"></li>
          </ul>
        </div>
        <span className="loading-txt">Loading</span>
      </div>
    </div>
  );
}

export default WrapLoading;
